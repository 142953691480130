import { Big } from 'big.js'
import { ArrowDown, Loader2, TrendingUp } from 'lucide-react'
import { useEffect, useMemo, useState } from 'react'
import useSWR from 'swr'

import { AVAILABLE_COUNTRIES } from '@/constants/countries'
import { fetchRate } from '@/lib/api'
import { getCurrencyCodeByCountry } from '@/lib/currency'
import { cn } from '@/lib/utils'
import { openDialog } from '@/store'

import { CurrencyInput } from './CurrencyInput'

type Props = {
  defaultAmount: string
  userCountry: string
  translations: {
    pay: string
    get: string
    title: string
    exchange: string
    digitalDollars: string
  }
}

export const CurrencyConvertor = ({
  defaultAmount,
  userCountry,
  translations,
}: Props) => {
  const [currencyAmountIn, setCurrencyAmountIn] = useState(defaultAmount)
  const [currencyAmountOut, setCurrencyAmountOut] = useState('')
  const [reverseCurrency, setReverseCurrency] = useState(false)

  const [currencyIn, setCurrencyIn] = useState(() =>
    getCurrencyCodeByCountry(userCountry ?? AVAILABLE_COUNTRIES[0].code),
  )

  const [currencyOut, setCurrencyOut] = useState('USDc')

  const hostname = import.meta.env.SSR ? '' : window.location.hostname

  const isProd = hostname.includes('.com')

  const { data: result, isLoading } = useSWR(
    `fetchRates`,
    () => fetchRate(reverseCurrency ? currencyOut : currencyIn, isProd),
    {
      refreshInterval: 10000,
    },
  )

  useEffect(() => {
    if (result && currencyAmountIn !== '') {
      const [rate] = result

      if (reverseCurrency) {
        setCurrencyAmountOut(
          new Big(currencyAmountIn).times(rate.bid).toFixed(2),
        )
        return
      }

      setCurrencyAmountOut(new Big(currencyAmountIn).div(rate.ask).toFixed(2))
    }
    // we specifically don't want to include currencyAmountIn here
  }, [result, reverseCurrency])

  const currentRate = useMemo(() => {
    if (result) {
      const [rate] = result

      return reverseCurrency ? rate.bid : rate.ask
    }

    return null
  }, [reverseCurrency, result])

  const handleCurrencyInChange = (value?: string) => {
    if (value === '' || !value) {
      setCurrencyAmountIn('')
      setCurrencyAmountOut('')
      return
    }

    setCurrencyAmountIn(value)

    if (currentRate) {
      if (reverseCurrency) {
        setCurrencyAmountOut(new Big(value).times(currentRate).toFixed(2))
        return
      }

      setCurrencyAmountOut(new Big(value).div(currentRate).toFixed(2))
    }
  }

  const handleCurrencyOutChange = (value?: string) => {
    if (value === '' || !value) {
      setCurrencyAmountIn('')
      setCurrencyAmountOut('')
      return
    }

    setCurrencyAmountOut(value)

    if (currentRate) {
      if (reverseCurrency) {
        setCurrencyAmountIn(new Big(value).div(currentRate).toFixed(2))
        return
      }

      setCurrencyAmountIn(new Big(value).times(currentRate).toFixed(2))
    }
  }

  const reverseCurrencies = () => {
    setReverseCurrency((prev) => !prev)

    setCurrencyIn(currencyOut)
    setCurrencyOut(currencyIn)
  }

  const visibleCurrencyIn =
    currencyIn === 'USDc' ? translations.digitalDollars : currencyIn

  const visibleCurrencyOut =
    currencyOut === 'USDc' ? translations.digitalDollars : currencyOut

  return (
    <div className="flex flex-col items-center max-w-xl p-6 md:p-0">
      <div className="p-3" />
      <h2 className="text-2.5xl md:text-5xl font-bold text-left md:text-center">
        {translations.title}
      </h2>
      <div className="p-8" />
      <div className="flex flex-col items-center bg-neutral-gray-100 md:bg-white rounded-2xl p-4 md:p-0">
      <div className="flex bg-white w-full justify-between md:bg-neutral-gray-100 rounded-2xl p-4 md:px-8 gap-0 sm:gap-3">
          <div className="flex flex-col sm:flex-row justify-between sm:justify-start items-start sm:items-center gap-1 sm:gap-3 shrink-0 md:w-56">
            {reverseCurrency ? (
              <img
                src="https://flagcdn.com/h120/us.webp"
                alt="United States flag"
                className="rounded-full size-[26px] sm:size-7 object-cover"
              />
            ) : (
              <img
                src={`https://flagcdn.com/h120/${
                  userCountry?.toLocaleLowerCase() ?? 'mx'
                }.webp`}
                alt={`${userCountry} flag`}
                className="rounded-full size-[26px] sm:size-7 object-cover"
              />
            )}
            <span className="text-xs sm:text-base md:text-2xl text-neutral-gray-1000 font-semibold">
              {visibleCurrencyIn}
            </span>
          </div>
          <div className="flex flex-col gap-1">
            <CurrencyInput
              type="text"
              inputMode="text"
              disabled={isLoading}
              allowNegativeValue={false}
              id="currency-in"
              name="user-currency-in"
              aria-label="User currency in"
              decimalsLimit={2}
              placeholder="- $0"
              value={currencyAmountIn}
              onValueChange={handleCurrencyInChange}
              maxLength={9}
              prefix="- $ "
              decimalSeparator="."
              className="w-full text-right bg-transparent tracking-tight text-xl md:text-2xl font-semibold outline-hidden md:h-14"
            />

            <p className="text-right text-xs md:hidden text-neutral-gray-400">
              {translations.pay}
            </p>
          </div>
        </div>

        <div
          className="relative z-2 flex items-center justify-center border-[10px] border-neutral-gray-100 max-w-lg md:border-white -mt-5 -mb-5 bg-primary w-14 md:w-16 h-14 md:h-16 rounded-full cursor-pointer md:hover:bg-primary-dark transition-colors"
          onClick={reverseCurrencies}
        >
          {isLoading ? (
            <Loader2 className="animate-spin text-white" />
          ) : (
            <ArrowDown className={cn('text-white w-5 h-5')} />
          )}
        </div>

        <div className="flex bg-white w-full justify-between md:bg-neutral-gray-100 rounded-2xl p-4 md:px-8 gap-0 sm:gap-3">
          <div className="flex flex-col sm:flex-row justify-between sm:justify-start items-start sm:items-center gap-1 sm:gap-3 shrink-0 md:w-56 pt-2 md:pt-0">
            {reverseCurrency ? (
              <img
                src={`https://flagcdn.com/h120/${
                  userCountry?.toLocaleLowerCase() ?? 'mx'
                }.webp`}
                alt={`${userCountry} flag`}
                className="rounded-full size-[26px] sm:size-7 object-cover"
              />
            ) : (
              <img
                src="https://flagcdn.com/h120/us.webp"
                alt="United States flag"
                className="rounded-full size-[26px] sm:size-7 object-cover"
              />
            )}

            <span className="text-xs sm:text-base md:text-2xl text-neutral-gray-1000 font-semibold">
              {visibleCurrencyOut}
            </span>
          </div>
          <div className="flex flex-col gap-1 pt-2 md:pt-0">
            <CurrencyInput
              type="text"
              inputMode="text"
              disabled={isLoading}
              allowNegativeValue={false}
              id="currency-out"
              name="user-currency-out"
              aria-label="User currency out"
              decimalsLimit={2}
              value={currencyAmountOut}
              onValueChange={handleCurrencyOutChange}
              maxLength={9}
              prefix="+ $ "
              placeholder="+ $0"
              decimalSeparator="."
              className="w-full text-right bg-transparent tracking-tight text-xl md:text-2xl font-semibold outline-hidden md:h-14"
            />

            <p className="text-right text-xs md:hidden text-neutral-gray-400">
              {translations.get}
            </p>
          </div>
        </div>
      </div>

      <div className="p-8" />

      <div className="flex items-center gap-4 mx-auto">
        <TrendingUp className="text-primary" />
        <span className="flex items-center gap-3 text-xl md:text-2xl font-semibold text-black">
          $1 ={' '}
          {isLoading ? (
            <div className="animate-pulse rounded-md bg-neutral-gray-600/10 w-24 h-6" />
          ) : (
            <>
              {Number(currentRate ?? 0).toLocaleString('en', {
                minimumFractionDigits: 4,
              })}{' '}
              {reverseCurrency ? currencyOut : currencyIn}
            </>
          )}
        </span>
      </div>

      <div className="p-8" />

      <button
        type="button"
        onClick={openDialog}
        className="bg-primary hover:bg-primary-dark text-xl md:text-xl text-white md:px-24 py-4 w-full md:w-fit rounded-full transition-colors font-semibold"
      >
        {translations.exchange}
      </button>
    </div>
  )
}
